import React from 'react';
import type { AppDebuggingWidget } from './AppDebuggingWidget';

let component: typeof AppDebuggingWidget | null = null;
if (process.env.APP_DEBUG) {
  (async function() {
    const widgetModule = await import("./AppDebuggingWidget") as any;
    component = widgetModule.AppDebuggingWidget;
  }());
}

export function AppDebuggingTool(): JSX.Element {
  return (
    <React.Fragment>
      { component && React.createElement(component) }
    </React.Fragment>
  );
}
