import { createListenerMiddleware } from '@reduxjs/toolkit';

import { baseApi } from './common';
import { Options } from "./common/options";
import { registerGuest, RegisterGuestSubject } from './guest-access-token/utils';
import { accessTokenStore } from './access-token/store';

export interface AccessTokenResponse {
  userId: string;
  isGuest: true;
  accessToken: string;
  tokenType: 'bearer';
  expiresIn: null;
}

export const guestAccessTokenApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    addGuestToken: build.mutation<AccessTokenResponse, void>({
      query: () => ({
        url: 'account/access-token/guest',
        method: 'POST',
        body: {},
      }),
      extraOptions: {
        auth: Options.AuthOption.__AuthIfAvailableAndBypassWaitingMutex,
      },
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        const result = await queryFulfilled;
        dispatch(accessTokenStore.actions.setGuestAccessToken(result.data));
      }
    }),
  }),
});

export const guestAccessTokenUtilMiddleware = createListenerMiddleware();
guestAccessTokenUtilMiddleware.startListening({
  matcher: registerGuest.pending.match,
  effect: async (action, listenerAPI) => {
    const result = await listenerAPI.dispatch(
      guestAccessTokenApi.endpoints.addGuestToken.initiate()
    );
    RegisterGuestSubject.dispatch(action.meta.requestId, result);
  },
});
