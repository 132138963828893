import { createListenerMiddleware } from '@reduxjs/toolkit'

import type { RootState } from 'core/store';
import { baseApi } from './common';
import { accessTokenSelector, guestTokenSelector } from './access-token/selectors';
import { accessTokenStore } from './access-token/store';
import { accessTokenApi } from './access-token';
import { guestAccessTokenApi } from './guest-access-token';
import { guestUserIdSelector } from './access-token/selectors';

export const guestAccountOwnerApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    updateGuestAccountOwner: build.mutation<void, { guestAccount: string }>({
      query: (body) => ({
        url: 'account/guest-account-owner',
        method: 'PUT',
        body: body,
      }),
      invalidatesTags: [{ type: 'GuestAccountOwner', id: 'SINGLETON' }],
    }),
  }),
})

export const guestAccountOwnerMiddleware = createListenerMiddleware();
guestAccountOwnerMiddleware.startListening({
  // when the user login, if they are (prevously) a guest,
  // i.e. have both access token & guest token
  // we need to 0) do the following once only (prevent async parallel)
  // 1) notify backend to reassign ownerships
  // 2) remove the guest account (locally)
  actionCreator: accessTokenStore.actions.setAccessToken,
  effect: async (_action, listenerAPI) => {
    try {
      listenerAPI.unsubscribe();

      const state = listenerAPI.getState() as RootState;
      const guestUserId = guestUserIdSelector(state);
      if (!guestUserId) {
        // no guest user id, assume there isn't a guest user, no-op
        return;
      }

      const result = await listenerAPI.dispatch(
        guestAccountOwnerApi.endpoints.updateGuestAccountOwner.initiate({
          guestAccount: guestUserId,
        })
      );

      if ('error' in result) {
        throw new Error("Unable to transfer guest account ownership");
      } else {
        listenerAPI.dispatch(accessTokenStore.actions.resetGuestAccessToken());
      }
    } finally {
      listenerAPI.subscribe();
    }
  }
});
