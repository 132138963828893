import type { Route } from "../../core";
import type OrgsOwnedPortalList from "./OrgsOwnedPortalList";

/**
 * OrgsOwnedPortalList route.
 */
const route = {
  path: "/home",
  getHref(
    modal?: (
      { ['type']: 'createSuccess', portalUrl: string } |
      { ['type']: 'updateSuccess', portalUrl: string }
    ),
  ) {
    const url = new URL(this.path as string, document.baseURI);
    switch (modal?.type) {
      case 'createSuccess': {
        url.searchParams.set('createdPortal', modal.portalUrl);
        break;
      }
      case 'updateSuccess': {
        url.searchParams.set('updatedPortal', modal.portalUrl);
        break;
      }
    }
    return url.href;
  },
  component: () => import(/* webpackChunkName: "my-portals" */ "./OrgsOwnedPortalList"),
  response: () => ({
    title: "My Portals",
    description: "",
    requireLogin: true,
  }),
};
export default route as Route<typeof OrgsOwnedPortalList> as typeof route;
