import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import { Mutex } from 'async-mutex';
import type { MutexInterface } from 'async-mutex';

import { config } from 'core/config';
import type { RootState, AppDispatch } from 'core/store';

import { retry } from './common/retry';
import { AuthOption, ExtraOptions } from "./common/options";
export { Options } from "./common/options";

import { registerGuest } from './guest-access-token/utils';
import { signOut } from './sign-out/actions';
import { accessTokenStore } from './access-token/store';
import { accessTokenSelector, guestTokenSelector } from './access-token/selectors';
import { getRefreshAccessTokenApi } from './access-token/refresher-api';

import { pushToDataLayer } from '../misc/google-tag-manager';

type FetchBaseQueryFn = BaseQueryFn<
  FetchArgs,
  Response,
  FetchBaseQueryError,
  ExtraOptions
>;

type FetchBaseQueryEnhancer<C = void> = <BaseQuery extends FetchBaseQueryFn>(
  baseQuery: BaseQuery,
  config: C
) => FetchBaseQueryFn

// reference https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
const tryAuthenticateOrGuest: FetchBaseQueryEnhancer = (
  baseQuery => async (args, api, extraOptions) => {
    await baseApi.authMutex.waitForUnlock();
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && (result.error.status === 401)) {
      // checking whether the mutex is locked
      if (!baseApi.authMutex.isLocked()) {
        const release = await baseApi.authMutex.acquire();
        let registerGuestSuccess: boolean;
        try {
          const result = await api.dispatch(registerGuest()).unwrap();
          registerGuestSuccess = 'data' in result;
        } finally {
          // release must be called once the mutex should be released again.
          release();
        }
        if (registerGuestSuccess) {
          // successfully register guest, retry the initial query
          result = await baseQuery(args, api, extraOptions);
        } else {
          api.dispatch(signOut());
        }
      } else {
        // wait until the mutex is available without locking it
        await baseApi.authMutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
    return result;
  }
);

const unauthorizeAndForceReSignin = (dispatch: AppDispatch) => {
  // TODO: less violent method to notify user logout?
  dispatch(signOut());
  const loginUrl = new URL('sign-in', location.origin);
  location.href = loginUrl.href;
}

const logoutIfForbidden: FetchBaseQueryEnhancer = (
  baseQuery => async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (result.error && result.error.status === 403) {
      unauthorizeAndForceReSignin(api.dispatch);
    }
    return result;
  }
);

function prepareAuthHeader(
  headers: Headers,
  getState: () => unknown,
  allowGuest: boolean
) {
  const headerName = 'Authorization';
  if (headers.has(headerName)) {
    // already have auth header / the caller override, no need to add it
    if (headers.get(headerName) === '') {
      // the given auth header was empty string,
      // signifying it was purely to prevent we adding it here,
      // so we need to remove it
      // if in the future it need to include a empty header,
      // we might need to change it to a magic value instead of empty string
      headers.delete(headerName);
    }
  } else {
    const state = getState() as RootState;
    let token = accessTokenSelector(state);
    if (!token && allowGuest) {
      token = guestTokenSelector(state)
    }
    if (token) {
      headers.set(headerName, `Bearer ${token}`)
    }
  }
}

function _basicParseJwt(token: string) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = window.atob(base64);
    return JSON.parse(jsonPayload);
}

const tryRefreshAuthToken: FetchBaseQueryEnhancer = (
  baseQuery => async (args, api, extraOptions) => {
    await (async function tryRefreshToken() {
      if (extraOptions?.auth === AuthOption.__AuthIfAvailableAndBypassWaitingMutex) {
        return;
      }

      const state = api.getState() as RootState;
      const token = accessTokenSelector(state);

      if (state.accessToken.isRefreshingToken) {
        // it's already refreshing, no need to refresh it again
      } else if (token) {
        const { exp, iat } = _basicParseJwt(token);
        const now = Date.now() / 1000;
        if (baseApi.authMutex.isLocked()) {
          // the mutex acquirer probably already getting a new token,
          // we don't need to refersh token here, not even trying to wait here
          // since the baseQuery should wait for the mutex if it is required
        } else if (exp < now) {
          unauthorizeAndForceReSignin(api.dispatch);
        } else if (now - iat > 60 * 60 * 24) {
          // refresh the token if it was issued more then a day ago
          // we don't acquire mutex since
          // the above if checks already imply that the existing token works.
          // and there is no reason to force request to use a new token

          api.dispatch(
            accessTokenStore.actions.setAccessTokenRefreshingStatus(true)
          );

          // p.s. guest token don't get to here due to `accessTokenSelector`
          const refresherApi = getRefreshAccessTokenApi(baseApi);

          if (exp - now < 60 * 3) {
            // however if the token is soon to be expired,
            // just have other requests wait
            const release = await baseApi.authMutex.acquire();
            await api.dispatch(
              refresherApi.endpoints.refreshAccessToken.initiate({})
            );
            release();
          } else {
            api.dispatch(
              refresherApi.endpoints.refreshAccessToken.initiate({})
            );
          }

          api.dispatch(
            accessTokenStore.actions.setAccessTokenRefreshingStatus(false)
          );
        }
      }
    }());

    return await baseQuery(args, api, extraOptions);
  }
);

const gaTrackRequests: FetchBaseQueryEnhancer<Set<String>> = (
  (baseQuery, methods) => async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions);
    if (args.method && methods.has(args.method)) {
      pushToDataLayer({
        event: (result.error ? "formSubmitFail" : 'gtm.formSubmit'),
        eventModel: {
          form_name: args.url,
          form_method: args.method,
        },
        "gtm.triggers": "",
      })
    }
    return result;
  }
)

const baseQueries = {
  [AuthOption.__AuthIfAvailableAndBypassWaitingMutex]: logoutIfForbidden(
    fetchBaseQuery({
      baseUrl: config.api.origin,
      prepareHeaders: async (headers, { getState }) => {
        prepareAuthHeader(headers, getState, false);
        return headers;
      },
    }) as FetchBaseQueryFn
  ),
  [AuthOption.Must]: logoutIfForbidden(
    tryRefreshAuthToken(
      fetchBaseQuery({
        baseUrl: config.api.origin,
        prepareHeaders: async (headers, { getState }) => {
          await baseApi.authMutex.waitForUnlock();
          prepareAuthHeader(headers, getState, false);
          return headers;
        },
      }) as FetchBaseQueryFn
    )
  ),
  [AuthOption.RetryWithGuest]: logoutIfForbidden(
    tryAuthenticateOrGuest(
      tryRefreshAuthToken(
        fetchBaseQuery({
          baseUrl: config.api.origin,
          prepareHeaders: async (headers, { getState }) => {
            await baseApi.authMutex.waitForUnlock();
            prepareAuthHeader(headers, getState, true);
            return headers;
          },
        }) as FetchBaseQueryFn
      )
    )
  ),
  [AuthOption.Ignore]: fetchBaseQuery({
    baseUrl: config.api.origin,
    prepareHeaders: (headers, { getState }) => {
      return headers;
    },
  }) as FetchBaseQueryFn,
}

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  Response,
  FetchBaseQueryError,
  ExtraOptions
> = async (args, api, extraOptions) => {
  const queryWithHeader = baseQueries[extraOptions?.auth || AuthOption.Must];
  const retryQuery = retry(queryWithHeader, { maxRetries: 0 });
  const gaTrackedQuery = gaTrackRequests(
    retryQuery,
    new Set(['POST', 'PUT', 'PATCH', 'DELETE'])
  )
  return await gaTrackedQuery(args, api, extraOptions);
}

export const baseApi = Object.assign(
  { authMutex: new Mutex() },
  createApi({
    baseQuery: baseQuery,
    endpoints: () => ({}),
    tagTypes: [
      'AccessToken', 'Portal', 'MyPortals', 'GuestAccountOwner',
      'AppConfig',
    ],
  }),
);
