import { Route, RouterContext, createUrl } from "core/router";
import type PortalCreateSuccessSignUp from "./PortalCreateSuccessSignUp";
import signUp, { preferredPath } from "../sign-up";

/**
 * PortalCreateSuccessSignUp route.
 */
const route = {
  ...signUp,
  getHref(params: { portalUrl: string | null, successRedirectHref: string | null }) {
    return createUrl(
      preferredPath,
      { portal: params.portalUrl, redirect: params.successRedirectHref },
    );
  },
  matchRoute: (ctx: RouterContext) => ctx.search.has('portal'),
  component: () => import(/* webpackChunkName: "claim-your-portal" */ "./PortalCreateSuccessSignUp"),
  response: () => ({
    title: "Claim your portal",
    description: "",
  }),
};
export default route as Route<typeof PortalCreateSuccessSignUp> as typeof route;
