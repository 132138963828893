// https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/ButtonGroup
export const MuiButtonGroup = {
  MuiButtonGroup: {
    styleOverrides: {
      root: {
        boxShadow: "none",
      },
    },
  },
};
