import type { Route } from "../../core";
import type EditPortalsHubForm from "./EditPortalsHubForm";

/**
 * EditPortalsHubForm route.
 */
export default {
  path: "/portals-hub/:organization/edit",
  getHref(organization) {
    return '/edit-portals-hub/' + organization;
  },
  component: () => import(/* webpackChunkName: "edit-portals-hub-form" */ "./EditPortalsHubForm"),
  response: (_, ctx) => ({
    title: "Edit Portals Hub",
    description: "",
    requireLogin: true,
    props: {
      hubOrganizationName: ctx.params.organization,
    },
  }),
} as Route<typeof EditPortalsHubForm>;
