import history from "history/browser";
import { createRoot } from 'react-dom/client';
import { App } from "./common";
import { setupGtm } from "./misc/google-tag-manager";

// Dehydrate the initial API response and initialize a Relay store
// https://developer.mozilla.org/docs/Web/HTML/Element/script#embedding_data_in_html
// const data = (document.getElementById("data") as HTMLScriptElement).text;
// const records = data ? JSON.parse(data) : undefined;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App history={history} />);

setupGtm(document.body);
