export const MuiButton = {
  // https://github.com/mui/material-ui/issues/32539
  // https://github.com/mui-org/material-ui/tree/next/packages/material-ui/src/Button
  MuiButton: {
    variants: [
      {
        props: { variant: "underlined" },
        style: {
          fontWeight: "400",
          paddingLeft: "0",
          paddingRight: "0",
          textDecoration: "underline",
          "&:hover": {
            textDecoration: "underline",
            backgroundColor: "transparent",
            color: "#8E2DE2",
          },
        },
      },
      // {
      //   props: { size: "extraLarge" },
      //   style: {
      //     fontSize: "20px",
      //   },
      // },
      {
        props: { textColor: "text" },
        style: {
          color: "rgba(0, 0, 0, 0.6)",
        },
      },
      {
        props: { textColor: "secondary" },
        style: {
          color: "#4E2FEA",
        },
      },
    ],
    styleOverrides: {
      contained: {
        textTransform: "capitalize",
        boxShadow:
          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
      },
      containedSecondary: {
        backgroundColor: "transparent",
        color: "#4E2FEA",
        boxShadow:
          "0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)",
        "&:hover": {
          backgroundColor: "#DCD4FF",
          boxShadow:
            "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
        },
      },
    },
  },
};
