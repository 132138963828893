import { useMemo } from "react";

import { useAppSelector } from "hooks/useRedux";
import { addAccessToken, accessTokenSelector } from "services/access-token/selectors";

interface AuthenticationStatus {
  isAuthenticated: boolean // guest is not considered authenticated
  isUnauthenticated: boolean
  isLoading: boolean
}

const initialStatus: AuthenticationStatus = {
  isAuthenticated: false,
  isUnauthenticated: false,
  isLoading: true,
};

export function useAuthenticationStatus(): AuthenticationStatus {
  const token = useAppSelector(accessTokenSelector);
  const result = useMemo(
    () => ({
      isAuthenticated: !!token,
      isUnauthenticated: !token,
      isLoading: !token,
    }),
    [ token ]
  );
  return result;
}
