import type { Route } from "../../core";
import type OAuthSuccessCallback from "./OAuthSuccessCallback";

/**
 * OAuthCallback route.
 */
export default {
  path: "/oauth-callback",
  getHref() { return this.path; },
  component: () => import(/* webpackChunkName: "oauth-callback" */ "./OAuthSuccessCallback"),
  response: () => ({
    title: "Logging in",
    description: "",
  }),
} as Route<typeof OAuthSuccessCallback>;
