import { configureStore } from '@reduxjs/toolkit';
import * as persist from 'redux-persist'
import { overlaySlice } from 'services/overlay';
import { accessTokenStore, accessTokenStoreReducer, accessTokenPersistMiddleware } from 'services/access-token';
import { guestAccessTokenUtilMiddleware } from 'services/guest-access-token';
import { guestAccountOwnerMiddleware } from 'services/guest-account-owner';
import { signOutMiddleware } from 'services/sign-out';
import { baseApi } from 'services/common';

import { config } from 'core/config';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    [accessTokenStore.name]: accessTokenStoreReducer,
    [overlaySlice.name]: overlaySlice.reducer,
  },
  middleware: getDefaultMiddleware => (
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          persist.FLUSH, persist.REHYDRATE, persist.PAUSE,
          persist.PERSIST, persist.PURGE, persist.REGISTER
        ],
      }
    }).concat(
      baseApi.middleware,
      accessTokenPersistMiddleware.middleware,
      guestAccessTokenUtilMiddleware.middleware,
      guestAccountOwnerMiddleware.middleware,
      signOutMiddleware.middleware,
    )
  ),
  devTools: config.app.env !== 'prod',
});

export const persistor = persist.persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
