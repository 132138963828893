/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

/**
 * Client-side application settings for the local development environment.
 */
export const local = {
  // Core application settings
  app: {
    name: "React App",
    origin: "http://localhost:3000",
    env: "local" as "local" | "test" | "prod",
    portalPreview: {
      origin: 'http://localhost:8000',
      path: '',
    },
    portalsHubPreview: {
      origin: 'http://localhost:8000',
      path: '',
    },
  },
  api: {
    origin: "https://json-hosting.mhttp.org/",
    jsonOrigin: "https://portal.mhttp.org/",
  },
  gtag: {
    gtmId: '',
  },
};

/**
 * Client-side application settings for the test / QA environment.
 */
export const test: typeof local = {
  app: {
    ...local.app,
    origin: "https://test.example.com",
    env: "test",
  },
  api: {
    ...local.api,
  },
  gtag: {
    ...local.gtag,
  },
};

/**
 * Client-side application settings for the production environment.
 */
export const prod: typeof local = {
  app: {
    ...local.app,
    origin: "https://example.com",
    env: "prod",
    portalPreview: {
      origin: 'https://portal.mhttp.org',
      path: '/world',
    },
    portalsHubPreview: {
      origin: 'https://portal.mhttp.org',
      path: '/world',
    },
  },
  api: {
    ...local.api,
  },
  gtag: {
    gtmId: 'GTM-K8QLBZM',
  },
};

export type Config = typeof local;
export default { local, test, prod };
