import type { Route } from "../../core";
import type PortalInfo from "./PortalInfo";

/**
 * PortalInfo route.
 */
const route: Route<typeof PortalInfo> = {
  path: "/portals/info",
  getHref(portalJsonUrl: string) {
    const url = new URL(this.path as string, document.baseURI);
    url.searchParams.set('url', portalJsonUrl);
    return url.href;
  },
  component: () => import(/* webpackChunkName: "portal-info" */ "./PortalInfo"),
  response: () => ({
    title: "Portal",
    description: "",
  }),
}
export default route;
