import { config } from "core/config";

declare let window: {
  gtag(...args: unknown[]): void;
  dataLayer: {
    push(obj: unknown): void
  }
};

export function setupGtm(parent: HTMLElement): void {
  const { gtmId } = config.gtag;
  if (!gtmId) return /* no gtm id, don't set up, no op */;
  const element = document.createElement('script');
  element.type = 'text/javascript';
  element.async = true;
  element.src = `https://www.googletagmanager.com/gtm.js?id=${gtmId}`;
  parent.appendChild(element);
}

export function gtag(...args: any[]): void {
  window.gtag(...args);
}

export function pushToDataLayer(obj: unknown): void {
  window.dataLayer.push(obj);
}
