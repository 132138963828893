import { createListenerMiddleware } from '@reduxjs/toolkit';

import { accessTokenStore } from '../access-token';

import { signOut } from './actions';

export const signOutMiddleware = createListenerMiddleware();
signOutMiddleware.startListening({
  actionCreator: signOut,
  effect: async (action, listenerAPI) => {
    listenerAPI.dispatch(accessTokenStore.actions.reset());
  }
});

export { signOut }
