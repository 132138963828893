export const MuiTableCell = {
  MuiTableCell: {
    styleOverrides: {
      head: {
        fontSize: "24px",
        fontWeight: "24px",
        letterSpacing: "0.17px",
        borderBottom: "0",
      },
      body: {
        fontWeight: "400",
        fontSize: "20px",
        lineHeight: "143%",
        borderBottom: "0",
      },
      footer: {
        borderBottom: "0",
      },
    },
  },
};
