import {
    createSlice,
    PayloadAction,
    createListenerMiddleware,
} from '@reduxjs/toolkit';

import { persistReducer, REHYDRATE } from 'redux-persist';
import defaultGetStoredState from 'redux-persist/lib/getStoredState';
import storage from 'redux-persist/lib/storage';
import storageSession from 'redux-persist/lib/storage/session';

import type { RootState } from 'core/store';

import { reducerPath } from './constants';
import type { AccessTokenResponse as GuestAccessToken } from '../guest-access-token';
import type { AccessTokenResponse as AccessToken } from '../access-token';

export const accessTokenStore = createSlice({
  name: reducerPath,
  initialState: {
    isRefreshingToken: false,
    user: null as AccessToken & { updatedAt: number } | null,
    guest: null as GuestAccessToken & { updatedAt: number } | null,
  },
  reducers: {
    setAccessTokenRefreshingStatus(state, action: PayloadAction<boolean>) {
      state.isRefreshingToken = action.payload;
    },
    setAccessToken(state, action: PayloadAction<AccessToken>) {
      state.user = { ...action.payload, updatedAt: Date.now() };
    },
    setGuestAccessToken(state, action: PayloadAction<GuestAccessToken>) {
      state.guest = { ...action.payload, updatedAt: Date.now() };
    },
    resetGuestAccessToken(state) {
      state.guest = null;
    },
    reset(state) {
      state.isRefreshingToken = false;
      state.user = null;
      state.guest = null;
    }
  },
});

class _reducer {
  static persistConfig = {
    key: 'access-token',
    storage: storage,
    whitelist: ['user', 'guest'],
  };
  baseReducer = accessTokenStore.reducer;
  _implementation: ReturnType<typeof persistReducer<ReturnType<_reducer['baseReducer']>>> ;

  func(...[state, action]: Parameters<_reducer['_implementation']>) {
    if (!this._implementation) {
      this._implementation = persistReducer(
        (this.constructor as typeof _reducer).persistConfig,
        this.baseReducer
      );
    }
    return this._implementation(
      state as Parameters<_reducer['_implementation']>[0],
      action
    );
  }
}

export const accessTokenPersistMiddleware = createListenerMiddleware();
accessTokenPersistMiddleware.startListening({
  type: REHYDRATE,
  effect: async (_action, listenerAPI) => {
    window.addEventListener(
      'storage',
      async <S extends ReturnType<_reducer['func']>>(event: StorageEvent) => {
        if (
          event.key === `persist:${_reducer.persistConfig.key}` &&
          event.newValue !== event.oldValue
        ) {
          // if storage has been updated from another window,
          // we can expect the local storage has newer/different
          // state than the one we have in redux/memory
          const storedState = (
            await defaultGetStoredState(_reducer.persistConfig) as S
          );
          const inMemoryRootState = await listenerAPI.getState() as RootState;
          const inMemoryState = inMemoryRootState[accessTokenStore.name];
          if (
            (storedState?.user?.updatedAt || 0) >
            (inMemoryState.user?.updatedAt || 0)
          ) {
            // the stored one is indeed newer than the in memory one,
            // we need to update the in memory one
            listenerAPI.dispatch(accessTokenStore.actions.setAccessToken(
              storedState.user as Exclude<S['user'], null>
            ));
          }
          if (
            // assume newer tab will already have access to the existing token
            // that mean if the token changed, the tab is doing it on purpose
            // so handle the guest same as the normal user
            (storedState?.guest?.updatedAt || 0) >
            (inMemoryState.guest?.updatedAt || 0)
          ) {
            listenerAPI.dispatch(accessTokenStore.actions.setGuestAccessToken(
              storedState.guest as Exclude<S['guest'], null>
            ));
          }
        }
      }
    );
  }
});

export const accessTokenStoreReducer = _reducer.prototype.func.bind(new _reducer());
