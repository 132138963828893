import React, { useMemo } from "react";
import { useTheme } from '@mui/material/styles';

export const CssThemeVariableInjector = () => {
  const theme = useTheme();
  const render = useMemo(
    () => (
      <style>
        :root {'{'}
          --paletee-error-main: { theme.palette.error.main };
        {'}'}
      </style>
    ),
    [ theme ]
  )
  return render;
}
