/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Match, MatchFunction } from "path-to-regexp";
import { match as createMatchFn } from "path-to-regexp";
// import { fetchQuery } from "react-relay";
import routes from "../routes";
import { NotFoundError } from "./errors";
import type { Route, RouterContext, RouterResponse } from "./router.types";

/**
 * Converts the URL path string to a RegExp matching function.
 *
 * @see https://github.com/pillarjs/path-to-regexp
 */
const matchUrlPath: (
  pattern: string[] | string,
  path: string
) => Match<{ [key: string]: string }> = (() => {
  const cache = new Map<string, MatchFunction<{ [key: string]: string }>>();
  return function matchUrlPath(pattern: string[] | string, path: string) {
    const key = Array.isArray(pattern) ? pattern.join("::") : pattern;
    let fn = cache.get(key);
    if (fn) return fn(path);
    fn = createMatchFn(pattern, { decode: decodeURIComponent });
    cache.set(key, fn);
    return fn(path);
  };
})();

export async function resolveRoute(
  ctx: RouterContext,
): Promise<RouterResponse> {
  try {
    // Find the first route matching the provided URL path string
    for (let route of routes) {
      const match = matchUrlPath(route.path, ctx.path);

      if (!match) continue;

      if ( ('matchRoute' in route) && !route.matchRoute?.(ctx) ) continue;

      ctx.params = match.params;

      // Prepare GraphQL query variables
      // const variables =
      //   typeof route.variables === "function"
      //     ? route.variables(ctx)
      //     : route.variables
      //     ? route.variables
      //     : Object.keys(match.params).length === 0
      //     ? undefined
      //     : match.params;

      // Fetch GraphQL query response and load React component in parallel
      const [importedModule, data] = await Promise.all([
        route.component?.(),
        Promise.resolve(null as any),
        // route.query &&
        //   fetchQuery(ctx.relay, route.query, variables, {
        //     fetchPolicy: "store-or-network",
        //   }).toPromise(),
      ]);

      const response = route.response(data, ctx);

      if (response) return {
        component: importedModule?.default,
        ...response,
      };
    }

    throw new NotFoundError();
  } catch (error) {
    return {
      title:
        error instanceof NotFoundError ? "Page not found" : "Application error",
      error,
    };
  }
}

export function createUrl(
  path: string,
  searchParams?: { [key: string]: any }
): string;
export function createUrl<Options extends { href?: boolean }>(
  path: string,
  searchParams?: { [key: string]: any },
  options?: Options
): Options['href'] extends true ? string : URL;
export function createUrl<Options extends { href?: boolean }>(
  path: string,
  searchParams?: { [key: string]: any },
  options?: Options
): string | URL {
  const convertHref = !options || 'href' in options ? true : !!options.href;

  const basePath = convertHref ? 'file://' : document.baseURI;
  const result = new URL(path, basePath);
  if (searchParams) Object.entries(searchParams).forEach(([key, value]) => {
    if (value !== undefined && value !== null) {
      result.searchParams.append(key, value);
    }
  });

  if (convertHref) {
    return result.href.slice(basePath.length);
  } else {
    return result;
  }
}

export type { RouterContext, RouterResponse as RouteResponse, Route };
