import { Route, createUrl } from "core/router";
import type SignUp from "./SignUp";

export const preferredPath = "/sign-up";

/**
 * SignIn route.
 */
const route = {
  path: "/(sign-up|signup)",
  getHref(params?: { redirect?: string | null, portalUrl?: string | null }) {
    return createUrl(
      preferredPath,
      { portal: params?.portalUrl, redirect: params?.redirect },
    );
  },
  component: () => import(/* webpackChunkName: "sign-up" */ "./SignUp"),
  response: () => ({
    title: "Sign Up",
    description: "",
  }),
};
export default route as Route<typeof SignUp> as typeof route;
