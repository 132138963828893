import type { Route } from "../../core";
import type NewPortalForm from "./NewPortalForm";

/**
 * PortalForm route.
 */
export default {
  path: "/(new|create)",
  getHref() {
    return '/new';
  },
  component: () => import(/* webpackChunkName: "new-portal-form" */ "./NewPortalForm"),
  response: () => ({
    title: "Create Portal | Create Immersive Portals | MHTTP",
    description: "Portals are virtual gateways that enable users to travel seamlessly between different virtual worlds in the Metaverse. Access the Metaverse now",
  }),
} as Route<typeof NewPortalForm>;
