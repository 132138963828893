import type { accessTokenStore } from '../access-token';
import { reducerPath } from './constants';

type RootState = {
  [reducerPath]: ReturnType<typeof accessTokenStore.reducer>,
};


export function accessTokenSelector<S extends RootState = RootState>(
  state: S,
): string | null {
  return state[reducerPath].user?.accessToken || null;
}

export function guestTokenSelector<S extends RootState = RootState>(
  state: S,
): string | null {
  return state[reducerPath].guest?.accessToken || null;
}

export function guestUserIdSelector<S extends RootState = RootState>(
  state: S,
): string | null {
  return state[reducerPath].guest?.userId || null;
}
