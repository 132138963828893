import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import type { guestAccessTokenApi } from '../guest-access-token';

type GuestTokenResponse = Awaited<
  ReturnType<ReturnType<
    typeof guestAccessTokenApi.endpoints.addGuestToken.initiate
  >>
>;

export const RegisterGuestSubject = new (class _RegisterGuestSubject {
  private listeners = {} as Record<string, (event: GuestTokenResponse) => void>;
  once(id: string, listener: _RegisterGuestSubject['listeners'][string]) {
    this.listeners[id] = listener;
  }
  dispatch(id: string, event: GuestTokenResponse) {
    const listener = this.listeners[id];
    listener(event);
    delete this.listeners[id];
  }
})();

export const registerGuest = createAsyncThunk<GuestTokenResponse>(
  'guest-access-token/utils/register-guest/init',
  (_arg, { requestId }) => new Promise(resolve => {
    RegisterGuestSubject.once(requestId, resolve);
  })
);
