import type { AccessTokenResponse, AccessTokenRequest } from "../access-token";
import type { baseApi } from "../common";
import { Options } from "../common/options";
import { accessTokenStore } from './store';

// since this refresher are supposed to be used in the baseApi,
// so it's not allow to cyclically import it and need to have it in a function
// alternativelly we could have copy the base api to a new api
// but that might make sync-ing the logic/changes problematic

let singleton: unknown = null;

export function getRefreshAccessTokenApi(base: typeof baseApi) {
  if (singleton) return singleton as typeof result;

  const result = base.injectEndpoints({
    endpoints: (build) => ({
      refreshAccessToken: build.mutation<AccessTokenResponse, AccessTokenRequest>({
        query: (payload) => ({
          url: 'account/access-token',
          method: 'POST',
          body: payload,
        }),
        extraOptions: {
          auth: Options.AuthOption.__YesAndBypassWaitingForMutex,
        },
        invalidatesTags: [
          { type: 'AccessToken', id: 'SINGLETON' },
        ],
        async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
          const result = await queryFulfilled;
          dispatch(accessTokenStore.actions.setAccessToken(result.data));
        }
      }),
    }),
  });

  singleton = result

  return result;
}
