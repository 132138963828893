import React, { useEffect } from 'react';

import type { RouterResponse } from 'core/router.types';
import { useHistory } from 'hooks/useHistory';
import { useAuthenticationStatus } from 'hooks/useAuthenticationStatus';

import homePage from "../routes/home";

interface Props {
  route: RouterResponse | null | undefined;
  children: JSX.Element[];
}

export function AuthGuard(props: Props): JSX.Element {
  const history = useHistory();
  const authenticationStatus = useAuthenticationStatus();
  const unauthenticated = (
    authenticationStatus.isUnauthenticated && props.route?.requireLogin
  );

  useEffect(
    () => {
      if (unauthenticated) {
        history.replace(homePage.getHref());
      }
    },
    [ unauthenticated ]
  );

  if (unauthenticated) {
    // prevent the child running query without access token
    return <React.Fragment/>;
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
}
