/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { ThemeOptions } from "@mui/material/styles";
import { MuiBackdrop } from "./MuiBackdrop";
import { MuiButton } from "./MuiButton";
import { MuiButtonBase } from "./MuiButtonBase";
import { MuiButtonGroup } from "./MuiButtonGroup";
import { MuiInput } from "./MuiInput";
import { MuiTableCell } from "./MuiTableCell";
import { MuiTableContainer } from "./MuiTableContainer";
import { MuiTablePagination } from "./MuiTablePagination";

/**
 * Style overrides for Material UI components.
 */
export const components: ThemeOptions["components"] = {
  ...MuiBackdrop,
  ...MuiButtonBase,
  ...MuiButton,
  ...MuiButtonGroup,
  ...MuiInput,
  ...MuiTableCell,
  ...MuiTableContainer,
  ...MuiTablePagination,
};
