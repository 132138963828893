import type { Route } from "../../core";
import type EditPortalForm from "./EditPortalForm";

/**
 * EditPortalForm route.
 */
export default {
  path: "/edit/:namespace?/:alias",
  getHref(namespace_, alias) {
    if (namespace_) {
      return `/edit/${namespace_}/${alias}`;
    } else {
      return `/edit/${alias}`;
    }
  },
  component: () => import(/* webpackChunkName: "edit-portal-form" */ "./EditPortalForm"),
  response: (_, ctx) => ({
    title: "Edit Portal",
    description: "",
    props: {
      portalAlias: ctx.params.alias,
      portalNamespace: ctx.params['namespace'],
    },
  }),
} as Route<typeof EditPortalForm>;
