import type {} from "@mui/lab/themeAugmentation";
import { PaletteMode } from "@mui/material";
import { createTheme, Theme } from "@mui/material/styles";
import { components } from "./components";

// @ts-expect-error: ts gonna complain not finding the css type definition
import("./global.scss"); // use function so it won't get tree shaken away

/**
 * Customized Material UI themes for "light" and "dark" modes.
 *
 * @see https://next.material-ui.com/customization/default-theme/
 */
const themes = (["light", "dark"] as PaletteMode[]).map((mode) =>
  createTheme(
    {
      palette: {
        mode,
        ...(mode === "light"
          ? {
              primary: {
                main: "#8E2DE2",
                dark: "#5A11C1",
                light: "#BD76FB",
                tint: "#EDE4FF",
              },
              secondary: {
                main: "#4E2FEA",
                dark: "#290BBB",
                light: "#BD76FB",
                tint: "#DCD4FF",
              },
            }
          : {}),
      },
      typography: {
        fontFamily: [
          `"Roboto"`,
          `-apple-system`,
          `"BlinkMacSystemFont"`,
          `"Segoe UI"`,
          `"Oxygen"`,
          `"Ubuntu"`,
          `"Cantarell"`,
          `"Fira Sans"`,
          `"Droid Sans"`,
          `"Helvetica Neue"`,
          `sans-serif`,
        ].join(","),
      },

      components,
    },
    {
      typography: {
        h1: {
          fontSize: "96px",
          fontWeight: "600",
          lineHeight: "116.7%" /* identical to box height, or 112px */,
          letterSpacing: "-1.5px",
        },
        h2: {
          fontSize: "60px",
          fontWeight: "500",
          lineHeight: "120%" /* identical to box height, or 72px */,
          letterSpacing: "-0.5px",
        },
        h3: {
          fontSize: "48px",
          fontWeight: "500",
          lineHeight: "116.7%" /* identical to box height, or 56px */,
        },
        h4: {
          fontSize: "34px",
          fontWeight: "400",
          lineHeight: "123.5%" /* identical to box height, or 42px */,
          letterSpacing: "0.25px",
        },
        h5: {
          fontSize: "24px",
          fontWeight: "600",
          lineHeight: "133.4%" /* identical to box height, or 32px */,
        },
        h6: {
          fontSize: "20px",
          fontWeight: "500",
          lineHeight: "160%" /* identical to box height, or 32px */,
          letterSpacing: "0.15px",
        },
        body1: {
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "150%" /* identical to box height, or 24px */,
          letterSpacing: "0.15px",
        },
        body2: {
          fontSize: "14px",
          fontWeight: "400",
          lineHeight: "143%" /* identical to box height, or 20px */,
          letterSpacing: "0.17px",
        },
        substitle1: {
          fontSize: "16px",
          fontWeight: "400",
          lineHeight: "175%" /* identical to box height, or 28px */,
          letterSpacing: "0.15px",
        },
        substitle2: {
          fontSize: "14px",
          fontWeight: "500",
          lineHeight: "157%" /* identical to box height, or 22px */,
          letterSpacing: "0.1px",
        },
        overline: {
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "266%" /* identical to box height, or 32px */,
          letterSpacing: "1px",
          textTransform: "uppercase",
        },
        caption: {
          fontSize: "12px",
          fontWeight: "400",
          lineHeight: "166%" /* identical to box height, or 20px */,
          letterSpacing: "0.4px",
        },
        button: {
          textTransform: "none",
        },
      },
    }
  )
);

export default {
  light: themes[0],
  dark: themes[0],
} as { [key in PaletteMode]: Theme };
