// copied from https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/retry.ts
// except this will not retry 400 response

import type { BaseQueryEnhancer } from '@reduxjs/toolkit/query';
import { retry as _retry } from '@reduxjs/toolkit/query/react';

const HandledError = (() => {
  // sadly the HandledError isn't exported, and need to do this hacky way
  // to extract the class back out
  try {
    _retry.fail(null);
  } catch (handledError) {
    return (handledError as Error).constructor;
  }
})() as any;

async function defaultBackoff(attempt: number = 0, maxRetries: number = 5) {
  const attempts = Math.min(attempt, maxRetries)

  const timeout = ~~((Math.random() + 0.4) * (300 << attempts)) // Force a positive int in the case we make this an option
  await new Promise((resolve) =>
    setTimeout((res: any) => resolve(res), timeout)
  )
}

export interface RetryOptions {
  /**
   * How many times the query will be retried (default: 5)
   */
  maxRetries?: number
  /**
   * Function used to determine delay between retries
   */
  backoff?: (attempt: number, maxRetries: number) => Promise<void>
}

const retryWithBackoff: BaseQueryEnhancer<
  unknown,
  RetryOptions,
  RetryOptions | void
> = (baseQuery, defaultOptions) => async (args, api, extraOptions) => {
  const options = {
    maxRetries: 5,
    backoff: defaultBackoff,
    ...defaultOptions,
    ...extraOptions,
  }
  let retry = 0

  while (true) {
    try {
      const result = await baseQuery(args, api, extraOptions)
      // baseQueries _should_ return an error property, so we should check for that and throw it to continue retrying
      if (result.error) {
        throw new HandledError(result);
      }
      return result
    } catch (e: any) {
      retry++
      if (
        e.throwImmediately ||
        retry > options.maxRetries ||
        e?.value?.error?.status === 400 // no need to retry if it is user error
      ) {
        if (e instanceof HandledError) {
          return e.value
        }

        // We don't know what this is, so we have to rethrow it
        throw e
      }
      await options.backoff(retry, options.maxRetries)
    }
  }
}

export const retry = /* @__PURE__ */ Object.assign(retryWithBackoff, { fail: _retry.fail })
