

export enum AuthOption {
  Must = 0,
  RetryWithGuest = 1,
  Ignore = 2,
  __AuthIfAvailableAndBypassWaitingMutex = 3,
}

interface ExtraOptionsAuth {
  auth?: AuthOption;
}

export type ExtraOptions =
  & ExtraOptionsAuth;

export const Options = {
  AuthOption,
}
