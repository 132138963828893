import { createUrl, Route } from "core/router";
import type SignIn from "./SignIn";

/**
 * SignIn route.
 */
const route = {
  path: "/(sign-in|signin)",
  getHref(params?: { redirect?: string | null, portalUrl?: string | null }) {
    return createUrl(
      '/sign-in',
      { portal: params?.portalUrl, redirect: params?.redirect },
    );
  },
  component: () => import(/* webpackChunkName: "sign-in" */ "./SignIn"),
  response: () => ({
    title: "Sign In | How to Teleport | MHTTP",
    description: "Build your portal here and experience virtual travel in the open Metaverse. MHTTP is an answer to the question, ‘is teleportation possible?'",
  }),
};
export default route as Route<typeof SignIn> as typeof route;
