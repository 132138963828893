import type { Route } from "../../core";
import type OAuthProviderSelection from "./OAuthProviderSelection";

/**
 * OAuthProviderSelection route.
 */
export default {
  path: "/authenticate",
  getHref() { return this.path; },
  component: () => import(/* webpackChunkName: "oauth-providers" */ "./OAuthProviderSelection"),
  response: () => ({
    title: "Login/Sign up",
    description: "",
  }),
} as Route<typeof OAuthProviderSelection>;
