import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OverlayState = {
  hidden: boolean
}

type State = Record<string, OverlayState>;

export const overlaySlice = createSlice({
  name: 'overlay',
  initialState: {} as State,
  reducers: {
    initializeOverlay(state, action: PayloadAction<{ key: string }>) {
      state[action.payload.key] = {
        hidden: true,
      };
    },
    showOverlay(state, action: PayloadAction<{ key: string }>) {
      state[action.payload.key].hidden = false;
    },
    hideOverlay(state, action: PayloadAction<{ key: string }>) {
      state[action.payload.key].hidden = true;
    },
  },
});

export const {
  initializeOverlay,
  showOverlay,
  hideOverlay,
} = overlaySlice.actions;
