import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { Action, Update } from "history";
import * as React from "react";
import { Provider as ReduxProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { CssBaseline, PaletteMode, Toolbar } from "@mui/material";
import { store, persistor } from 'core/store';
import { History, HistoryContext, LocationContext } from "core/history";
import type { RouteResponse } from "core/router";
import { resolveRoute } from "core/router";
import { LoginDialog } from "../dialogs";
import theme from "../theme";
import { AppToolbar } from "./AppToolbar";
import { ErrorPage } from "./ErrorPage";
import { AuthGuard } from './AuthGuard';
import { GoogleTagManager } from './GoogleTagManager';
import { AppDebuggingTool } from './AppDebuggingTool'
import { CssThemeVariableInjector } from './CssThemeVariableInjector';

type AppProps = {
  history: History;
};

export class App extends React.Component<AppProps> {
  state = {
    route: undefined as RouteResponse | undefined,
    location: this.props.history.location,
    error: undefined as Error | undefined,
    theme: (window?.localStorage?.getItem("theme") === "dark"
      ? "dark"
      : "light") as PaletteMode,
  };

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  dispose?: () => void;

  componentDidMount(): void {
    const { history } = this.props;
    this.dispose = history.listen(this.renderPath);
    this.renderPath({ location: history.location, action: Action.Pop });
  }

  componentDidUpdate(): void {
    if (this.state.route?.title) {
      self.document.title = this.state.route.title;
      const description = self.document.querySelector("meta[name='description']")
      description?.setAttribute("content", this.state.route.description as string)
    }
  }

  componentWillUnmount(): void {
    if (this.dispose) this.dispose();
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  renderPath = async (ctx: Update): Promise<void> => {
    const route = await resolveRoute({
      path: ctx.location.pathname,
      search: new URLSearchParams(ctx.location.search),
    });
    if (route.error) console.error(route.error);
    this.setState({ route, location: ctx.location, error: route.error });
  };

  handleChangeTheme = (): void => {
    this.setState((x: { theme: PaletteMode }) => {
      const theme = x.theme === "light" ? "dark" : "light";
      window.localStorage?.setItem("theme", theme);
      return { ...x, theme };
    });
  };

  render(): JSX.Element {
    const { history } = this.props;
    const { route, location, error } = this.state;

    if (error) {
      return (
        <ThemeProvider theme={theme[this.state.theme]}>
          <ErrorPage error={error} history={history} />;
        </ThemeProvider>
      );
    }

    return (
      <ReduxProvider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme[this.state.theme]}>
              <HistoryContext.Provider value={history}>
                <LocationContext.Provider value={location}>
                  <AppDebuggingTool />
                  <CssThemeVariableInjector />
                  <CssBaseline />
                  <AuthGuard route={route}>
                    { /* <AppToolbar onChangeTheme={this.handleChangeTheme} /> */ }
                    { /* <Toolbar /> */ }
                    {route?.component
                      ? React.createElement(route.component, route.props)
                      : null}
                    { /* <LoginDialog /> */ }
                  </AuthGuard>
                </LocationContext.Provider>
              </HistoryContext.Provider>
            </ThemeProvider>
          </StyledEngineProvider>
        </PersistGate>
      </ReduxProvider>
    );
  }
}
