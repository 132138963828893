import account from "./account";
import home from "./home";
import signIn from "./sign-in";
import newPortalForm from "./new-portal-form";
import editPortalForm from "./edit-portal-form";
import portalInfo from "./portal-info";
import portalCreateSuccessSignUp from "./portal-create-success-sign-up";
import signUp from "./sign-up";
// import portalCreateSuccess from "./portal-create-success";
import oauthProviderSelection from "./oauth-provider-selection";
import oauthSuccessCallback from "./oauth-success-callback";
import orgsPortalList from "./orgs-portal-list";
import editPortalsHubForm from "./edit-portals-hub-form";
import legal from "./legal";

/**
 * The list of application routes (pages).
 */
export default [
    home,
    signIn,
    account,
    newPortalForm,
    editPortalForm,
    portalInfo,
    portalCreateSuccessSignUp,
    signUp,
    // portalCreateSuccess,
    oauthProviderSelection,
    oauthSuccessCallback,
    orgsPortalList,
    editPortalsHubForm,
    ...legal,
] as const;
